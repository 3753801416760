<template>
    <main class="p-5 mt-2">
        <div class="top-header flex justify-between items-center align-items-center">
            <div class="text-xl font-semibold">{{ $t('updateWebshop') }}</div>
        </div>
        
        <div class="mt-10">
            <div class="absolute bg-red-30 inset-0 z-10" @click="removeModal" v-if="modal"></div>
            <div class="absolute bg-red-30 inset-0 z-10" @click="removeModal" v-if="countryModal"></div>
            <div class="grid grid-cols-1 sm:grid-cols-3 md:grid-cols-3 lg:grid-cols-3 gap-4">
                <div class="col-span-3 flex flex-col items-center align-items-center space-x-4 border shadow-sm rounded-md shadow-sm overflow-hidden">
                    <div class="flex justify-between w-full bg-light-teal p-2 border-b">
                        <div class="grid grid-cols-1 sm:grid-cols-2 gap-3 md:gap-10">
                            <h2 @click="tab = 1" class="text-sm cursor-pointer">{{ $t('webshopInformation') }}</h2>
                            <h2 @click="tab = 2" class="text-sm cursor-pointer md:mt-0">{{ $t('orderSettings') }}</h2>
                        </div>

                    </div>
                    <form v-if="tab === 1" @submit.prevent="updateWebshop" class="flex flex-col md:flex-row w-full">
                        <div class="flex flex-col w-full md:w-1/2">
                            <div class="flex flex-col md:flex-row md:justify-between md:items-center md:w-3/4 p-2">
                                <span class="text-sm text-gray-600 w-1/2 mb-2">{{ $t('shopName') }}</span>
                                <div class="form-group w-full md:w-3/4 pr-3 md:pr-0">
                                    <input type="text" v-model="webshopData.shop_name" class="w-full rounded p-2 border focus:outline-none focus:border-blue-300 text-gray-600 text-xs" style="outline: currentcolor none medium;">
                                </div>
                            </div>
                            <div class="flex flex-col md:flex-row md:justify-between md:items-center md:w-3/4 p-2">
                                <span class="text-sm text-gray-600 w-1/2 mb-2">{{ $t('dropship') }}</span>
                                <div class="form-group w-full md:w-3/4 pr-3 md:pr-0">
                                    <input type="text" v-model="webshopData.dropship" class="w-full rounded p-2 border focus:outline-none focus:border-blue-300 text-gray-600 text-xs" style="outline: currentcolor none medium;">
                                </div>
                            </div>
                            <div class="flex flex-col md:flex-row md:justify-between md:items-center md:w-3/4 p-2">
                                <span class="text-sm text-gray-600 w-1/2 mb-2">{{ $t('country') }}</span>
                                <div class="form-group w-full md:w-3/4 pr-3 md:pr-0">
                                    <input type="text" v-model="countryText" @input="searchCountry" @focus="countryModal = true" class="w-full rounded p-2 border focus:outline-none focus:border-blue-300 text-gray-600 text-xs" style="outline: currentcolor none medium;">
                                    <div class="relative">
                                        <div v-if="countryModal && countryResults.length > 0" class="absolute w-56 bg-white z-50 rounded border shadow-xl top-rem">
                                            <ul>
                                                <li class="py-1 pl-2 hover:bg-gray-100 text-gray-700 cursor-pointer text-sm" v-for="(item, i) in countryResults.slice(0, 5)" :key="i" @click="setCountry(item)">{{ item.name }}</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="flex flex-col md:flex-row md:justify-between md:items-center md:w-3/4 p-2">
                                <span class="text-sm text-gray-600 w-1/2 mb-2">{{ $t('shopPhone') }}</span>
                                <div class="form-group w-full md:w-3/4 pr-3 md:pr-0">
                                    <input type="text" v-model="webshopData.contact_phone" class="w-full rounded p-2 border focus:outline-none focus:border-blue-300 text-gray-600 text-xs" style="outline: currentcolor none medium;">
                                </div>
                            </div>
                            <div class="flex flex-col md:flex-row md:justify-between md:items-center md:w-3/4 p-2">
                                <span class="text-sm text-gray-600 w-1/2 mb-2">{{ $t('shopEmail') }}</span>
                                <div class="form-group w-full md:w-3/4 pr-3 md:pr-0">
                                    <input type="email" v-model="webshopData.shop_owner_email" class="w-full rounded p-2 border focus:outline-none focus:border-blue-300 text-gray-600 text-xs" style="outline: currentcolor none medium;">
                                </div>
                            </div>
                            <div class="flex flex-col md:flex-row md:justify-between md:items-center md:w-3/4 p-2">
                                <span class="text-sm text-gray-600 w-1/2 mb-2">{{ $t('shopURL') }}</span>
                                <div class="form-group w-full md:w-3/4 pr-3 md:pr-0">
                                    <input type="text" v-model="webshopData.shop_url" class="w-full rounded p-2 border focus:outline-none focus:border-blue-300 text-gray-600 text-xs" style="outline: currentcolor none medium;">
                                </div>
                            </div>
                            <div class="flex flex-col md:flex-row md:justify-between md:items-center md:w-3/4 p-2">
                                <span class="text-sm text-gray-600 w-1/2 mb-2">{{ $t('apiStore') }}</span>
                                <div class="form-group w-full md:w-3/4 pr-3 md:pr-0">
                                    <input type="text" v-model="webshopData.apiStoreName" class="w-full rounded p-2 border focus:outline-none focus:border-blue-300 text-gray-600 text-xs" style="outline: currentcolor none medium;">
                                </div>
                            </div>
                            <div class="flex flex-col md:flex-row md:justify-between md:items-center md:w-3/4 p-2">
                                <span class="text-sm text-gray-600 w-1/2 mb-2">{{ $t('clientSecret') }}</span>
                                <div class="form-group w-full md:w-3/4 pr-3 md:pr-0">
                                    <input type="text" v-model="webshopData.secret" class="w-full rounded p-2 border focus:outline-none focus:border-blue-300 text-gray-600 text-xs" style="outline: currentcolor none medium;">
                                </div>
                            </div>
                            <div class="flex flex-col md:flex-row md:justify-between md:items-center md:w-3/4 p-2">
                                <span class="text-sm text-gray-600 w-1/2 mb-2">{{ $t('clientID') }}</span>
                                <div class="form-group w-full md:w-3/4 pr-3 md:pr-0">
                                    <input type="text" v-model="webshopData.clientId" class="w-full rounded p-2 border focus:outline-none focus:border-blue-300 text-gray-600 text-xs" style="outline: currentcolor none medium;">
                                </div>
                            </div>
                            <div class="flex flex-col md:flex-row md:justify-between md:items-center md:w-3/4 p-2">
                                <span class="text-sm text-gray-600 w-1/2 mb-2">{{ $t('sendEmailTrackingAlert') }}</span>
                                <div class="form-group w-full md:w-3/4 pr-3 md:pr-0">
                                    <input type="checkbox" class="checkbox" v-model="webshopData.email_tracking_alerts_to_buyer">
                                </div>
                            </div>
                            <!-- <div class="flex flex-col md:flex-row md:justify-between md:items-center md:w-3/4 p-2">
                                <span class="text-sm text-gray-600 w-1/2 mb-2">Log</span>
                                <div class="form-group w-full md:w-3/4 pr-3 md:pr-0">
                                    <input type="text" v-model="webshopData.logo" class="w-full rounded p-2 border focus:outline-none focus:border-blue-300 text-gray-600 text-xs" style="outline: currentcolor none medium;">
                                </div>
                            </div> -->
                        </div>
                        <div class="flex flex-col w-full md:w-1/2">
                            <div class="flex flex-col md:flex-row md:justify-between md:items-center md:w-3/4 p-2">
                                <span class="text-sm text-gray-600 w-1/3 mb-2">{{ $t('emailServer') }}</span>
                                <div class="form-group w-full md:w-3/4 pr-3 md:pr-0">
                                    <input type="text" v-model="webshopData.email_server" class="w-full rounded p-2 border focus:outline-none focus:border-blue-300 text-gray-600 text-xs" style="outline: currentcolor none medium;">
                                </div>
                            </div>
                            <div class="flex flex-col md:flex-row md:justify-between md:items-center md:w-3/4 p-2">
                                <span class="text-sm text-gray-600 w-1/3 mb-2">{{ $t('emailSender') }}</span>
                                <div class="form-group w-full md:w-3/4 pr-3 md:pr-0">
                                    <input type="text" v-model="webshopData.sender" class="w-full rounded p-2 border focus:outline-none focus:border-blue-300 text-gray-600 text-xs" style="outline: currentcolor none medium;" required>
                                </div>
                            </div>
                            <div class="flex flex-col md:flex-row md:justify-between md:items-center md:w-3/4 p-2">
                                <span class="text-sm text-gray-600 w-1/3 mb-2">{{ $t('emailPassword') }}</span>
                                <div class="form-group w-full md:w-3/4 pr-3 md:pr-0">
                                    <input type="password" v-model="webshopData.password" class="w-full rounded p-2 border focus:outline-none focus:border-blue-300 text-gray-600 text-xs" style="outline: currentcolor none medium;" required>
                                </div>
                            </div>
                            <div class="flex flex-col md:flex-row md:justify-between md:items-center md:w-3/4 p-2">
                                <span class="text-sm text-gray-600 w-1/3 mb-2">{{ $t('emailPort') }}</span>
                                <div class="form-group w-full md:w-3/4 pr-3 md:pr-0">
                                    <input type="text" v-model="webshopData.incoming_port" class="w-full rounded p-2 border focus:outline-none focus:border-blue-300 text-gray-600 text-xs" style="outline: currentcolor none medium;" required>
                                </div>
                            </div>
                            <div class="flex flex-col md:flex-row md:justify-between md:items-center md:w-3/4 p-2">
                                <span class="text-sm text-gray-600 w-1/3 mb-2">{{ $t('emailCopy') }}</span>
                                <div class="form-group w-full md:w-3/4 pr-3 md:pr-0">
                                    <input type="text" v-model="webshopData.cc_email_receiver" class="w-full rounded p-2 border focus:outline-none focus:border-blue-300 text-gray-600 text-xs" style="outline: currentcolor none medium;" required>
                                </div>
                            </div>
                            <div class="flex flex-col md:flex-row md:justify-between md:items-center md:w-3/4 p-2">
                                <span class="text-sm text-gray-600 w-1/3 mb-2">{{ $t('emailSubject') }}</span>
                                <div class="form-group w-full md:w-3/4 pr-3 md:pr-0">
                                    <input type="text" v-model="webshopData.email_subject" class="w-full rounded p-2 border focus:outline-none focus:border-blue-300 text-gray-600 text-xs" style="outline: currentcolor none medium;" required>
                                </div>
                            </div>
                            <div class="flex flex-col md:flex-row md:justify-between md:items-center md:w-3/4 p-2">
                                <span class="text-sm text-gray-600 w-1/3 mb-2"></span>
                                <div class="form-group w-full md:w-3/4 pr-3 md:pr-0">
                                    <div class="flex mb-2">
                                        <button type="submit" :disabled="processing" class="bg-theme-blue text-white px-4 py-1 rounded-md shadow-md focus:outline-none cursor-pointer">
                                            <span v-if="processing">{{ $t('updating') }}</span>
                                            <span v-else>{{ $t('save') }}</span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <!-- <div class="flex flex-col md:flex-row md:justify-between md:items-center md:w-3/4 p-2">
                                <span class="text-sm text-gray-600 w-1/4 mb-2">Note</span>
                                <div class="form-group w-full md:w-3/4 pr-3 md:pr-0">
                                    <textarea cols="30" v-model="webshopData.note"  rows="5" class="w-full rounded p-2 border focus:outline-none focus:border-blue-300 text-gray-600 text-xs" style="outline: currentcolor none medium;" required></textarea>
                                </div>
                            </div> -->
                        </div>
                    </form>
                    <form  v-if="tab === 2" @submit.prevent="patchAdditionalInfo" class="flex flex-col md:flex-row w-full">
                        <div class="flex flex-col w-full md:w-1/2">
                            <div class="flex flex-col md:flex-row md:justify-between md:items-center md:w-3/4 p-2">
                                <span class="text-sm text-gray-600 w-1/2 mb-2">{{ $t('startTime') }}</span>
                                <div class="form-group w-full md:w-3/4 pr-3 md:pr-0">
                                    <input v-model="webshopSettings.post_order_start_time" type="time" class="w-full rounded p-2 border focus:outline-none focus:border-blue-300 text-gray-600 text-xs" style="outline: currentcolor none medium;" required>
                                </div>
                            </div>
                            <div class="flex flex-col md:flex-row md:justify-between md:items-center md:w-3/4 p-2">
                                <span class="text-sm text-gray-600 w-1/2 mb-2">{{ $t('stopTime') }}</span>
                                <div class="form-group w-full md:w-3/4 pr-3 md:pr-0">
                                    <input v-model="webshopSettings.post_order_end_time" type="time" class="w-full rounded p-2 border focus:outline-none focus:border-blue-300 text-gray-600 text-xs" style="outline: currentcolor none medium;" required>
                                </div>
                            </div>
                            <div class="flex flex-col md:flex-row md:justify-between md:items-center md:w-3/4 p-2">
                                <span class="text-sm text-gray-600 w-1/2 mb-2">{{ $t('timezone') }}</span>
                                <div class="form-group w-full md:w-3/4 pr-3 md:pr-0">
                                    <input v-model="webshopSettings.post_order_timezone" @input="searchField" @focus="modal = true" type="text" class="w-full rounded p-2 border focus:outline-none focus:border-blue-300 text-gray-600 text-xs" style="outline: currentcolor none medium;" required>
                                    <div v-if="modal && searchResults.length > 0" class="absolute w-56 bg-white z-50 rounded border shadow-xl top-rem">
                                        <ul>
                                            <li class="py-1 pl-2 hover:bg-gray-100 text-gray-700 cursor-pointer text-sm" v-for="(item, i) in searchResults.slice(0, 5)" :key="i" @click="setTimeZone(item)">{{ item }}</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div class="flex flex-col md:flex-row md:justify-between md:items-center md:w-3/4 mt-10 mb-20 p-2">
                                <span class="text-sm text-gray-600 w-1/2 mb-2"></span>
                                <div class="form-group w-full md:w-3/4 pr-3 md:pr-0">
                                    <button type="submit" :disabled="processing" class="bg-theme-blue text-white px-4 py-1 rounded-md shadow-md focus:outline-none cursor-pointer w-full">
                                        <span v-if="processing">{{ $t('saving') }}</span>
                                        <span v-else>{{ $t('save') }}</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>

    </main>
</template>

<script>
import { mapGetters } from 'vuex'
import Bus from '../../bus'
import { getCountries } from './helper/countries'
// import { getTimeZone } from './helper/timezone'

export default {
    name : 'add-webshop',
    data () {
        return {
            processing : false,
            timezone : [],
            tab : 1,
            webshopData : {},
            modal : false,
            countryModal : false,
            showSearchResult : false,
            countries : getCountries(),
            searchResults : [],
            countryResults : [],
            countryText : '',
            timezoneText : '',
            webshopSettings : {
                post_order_timezone : ''
            }
        }
    },
    computed : {
        ...mapGetters({
            USER_REFRESH : 'auth/USER_REFRESH',
            GET_WEBSHOPS : 'jitcontrol/GET_WEBSHOPS',
            GET_USER_INFORMATION : 'jitcontrol/GET_USER_INFORMATION',
            GET_CHOOSEN_COMPANY : 'jitcontrol/GET_CHOOSEN_COMPANY',
            GET_SINGLE_WEBSHOP : 'jitcontrol/GET_SINGLE_WEBSHOP'
        })
    },
    mounted () {
        this.getTimeZone()
        const { id } = this.$route.params
        if (id) {
            this.getUser(id)
        }
    },
    methods : {
        getUser (id) {
            if (this.GET_CHOOSEN_COMPANY) {
                this.loading = true
                const payload = {
                    company : this.GET_CHOOSEN_COMPANY,
                    uuid : id
                }
                this.getSingleWebshop(payload)
                this.getSubscriptionSettings(payload)
            }
        },
        getTimeZone () {
            const payload = {
                URL : this.$services.endpoints.TIMEZONE_ENDPOINT
            }
            this.$store.dispatch('jitcontrol/customGetRequest', payload)
            .then(res => {
                this.timezone = res.data
            })
            .catch(err => {
                this.loading = false
                if (err.response.status === 401) {
                    this.$store.dispatch('auth/refreshToken', { refresh : this.USER_REFRESH })
                    .then(_ =>{
                        this.getTimeZone()
                    })
                    .catch(_ => {
                        this.$store.commit('auth/CLEAR_AUTH_USER', null)
                        Bus.emit('sign-out')
                    })
                }
            })
        },
        getSubscriptionSettings (data) {
            const payload = {
                URL : data.company + `/${this.$services.endpoints.SUBSCRIPTION_ENDPOINTS}/${data.uuid}/subscription_settings`,
            }
            this.$store.dispatch('jitcontrol/customGetRequest', payload)
            .then(res => {
                this.webshopSettings = res.data
                // this.timezoneText = res.data.post_order_timezone
            })
            .catch(err => {
                this.loading = false
                if (err.response.status === 401) {
                    this.$store.dispatch('auth/refreshToken', { refresh : this.USER_REFRESH })
                    .then(_ =>{
                        this.getTimeZone()
                    })
                    .catch(_ => {
                        this.$store.commit('auth/CLEAR_AUTH_USER', null)
                        Bus.emit('sign-out')
                    })
                }
            })
        },
        getSingleWebshop (data) {
            this.loading = true
            this.$store.dispatch('jitcontrol/getSingleWebshop', data)
            .then(_ => {
                this.loading = false
                this.webshopData = this.GET_SINGLE_WEBSHOP
                this.timezoneText = this.GET_SINGLE_WEBSHOP.post_order_timezone
                this.countryText = this.filterCountry(this.GET_SINGLE_WEBSHOP.country_code)
            })
            .catch(err => {
                this.loading = false
                if (err.response.status === 401) {
                    this.$store.dispatch('auth/refreshToken', { refresh : this.USER_REFRESH })
                    .then(_ =>{
                        this.getSingleWebshop(data)
                    })
                    .catch(_ => {
                        this.$store.commit('auth/CLEAR_AUTH_USER', null)
                        Bus.emit('sign-out')
                    })
                }
            })
        },
        updateWebshop () {
            this.processing = true
            const company = this.GET_CHOOSEN_COMPANY
            const { id } = this.$route.params
            this.webshopData.URL = company + `/${this.$services.endpoints.SUBSCRIPTION_ENDPOINTS}/${id}`
            this.$store.dispatch('jitcontrol/customPatchRequest', this.webshopData)
            .then(res => {
                this.processing = false
                this.$services.helpers.notification(this.$t('updatedWebshop'), 'success', this)
            })
            .catch(err => {
                this.processing = false
                if (err.response.status === 401) {
                    this.$store.dispatch('auth/refreshToken', { refresh : this.USER_REFRESH })
                    .then(_ =>{
                        this.updateWebshop()
                    })
                    .catch(_ => {
                        this.$store.commit('auth/CLEAR_AUTH_USER', null)
                        Bus.emit('sign-out')
                    })
                }
                if (err.response.status === 400) {
                    this.$services.helpers.notification(err.response.data, 'error', this)
                }
            })
        },
        patchAdditionalInfo () {
            this.processing = true
            const company = this.GET_CHOOSEN_COMPANY
            const { id } = this.$route.params
            const payload = {
                URL : company + `/${this.$services.endpoints.SUBSCRIPTION_ENDPOINTS}/${id}/subscription_settings`,
                post_order_start_time : this.webshopSettings.post_order_start_time,
                post_order_end_time : this.webshopSettings.post_order_end_time,
                post_order_timezone : this.webshopSettings.post_order_timezone,
            }
            this.$store.dispatch('jitcontrol/customPatchRequest', payload)
            .then(res => {
                this.processing = false
                this.$services.helpers.notification(this.$t('updatedWebshop'), 'success', this)
            })
            .catch(err => {
                this.processing = false
                if (err.response.status === 401) {
                    this.$store.dispatch('auth/refreshToken', { refresh : this.USER_REFRESH })
                    .then(_ =>{
                        this.patchAdditionalInfo()
                    })
                    .catch(_ => {
                        this.$store.commit('auth/CLEAR_AUTH_USER', null)
                        Bus.emit('sign-out')
                    })
                }
                if (err.response.status === 400) {
                    this.$services.helpers.notification(err.response.data, 'error', this)
                }
            })
        },
        searchCountry () {
            this.countryResults = this.countries.filter(country => {
                return String(country.key.toLowerCase()).includes(this.countryText.toLowerCase())
            })
        },
        setCountry (item) {
            this.countryText = item.name
            this.webshopData.country_code = item.key
            this.countryModal = false
        },
        searchField () {
            this.searchResults = this.timezone.filter(zone => {
                return String(zone.toLowerCase()).includes(this.webshopSettings.post_order_timezone.toLowerCase())
            })
        },
        setTimeZone (item) {
            this.timezoneText = item
            this.webshopSettings.post_order_timezone = item
            this.modal = false
        },
        filterCountry (code) {
            const filter = this.countries.filter(item => item.key === code)
            if (filter.length > 0) {
                return filter[0].name
            }
            return filter
        },
        moveNexStep () {
            // this.tab = 2
        },
        removeModal () {
            this.modal = false
            this.countryModal = false
        }
    }
}
</script>

<style lang="scss" scoped>

</style>